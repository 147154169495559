import _ from 'lodash';
import { Link } from 'react-router-dom';
import { AnimationAddClassWhenVisible } from '../../../components/ui/AnimationAddClassWhenVisible';
import { PartLogo, usePartLogo } from '../parts/PartLogo';
import { compileStringTemplate, lineBreaks } from '../../../libs/utils';
import { Background } from '../parts/PartBackground';
import { BtnsList, sectionCardBtnsFields } from './BlockSectionCardBtns';
import useBreakpoints from '../../../libs/useBreakpoints';
import { urlPrefix } from '../../instance/utilsInstance';


export default function () {
  return {
    type: 'pageHeader',
    variant: 'heroStatic',
    // description: 'Incluye una imagen de fondo estática, ocupa todo el ancho y casi todo el alto de la pantalla.',
    template: PageHeaderHeroStatic,
    // fields of params tab
    params: {
      'design': {
        type: 'select',
        options: ['mini', 'full'],
        defaultValue: 'full'
      },
      'headerBg': {
        name: 'Fondo',
        type: 'part',
        part: 'background'
      },
      'headerLogo': {
        name: 'Logo',
        type: 'part',
        part: 'logo'
      },
    },
    // fields of text tab
    strings: {
      'title': {
        type: 'text',
        name: 'Título'
      },
      'subtitle': {
        type: 'text',
        name: 'Subtítulo'
      },
      'btnList': {
        type: 'collection',
        name: 'Botones',
        fields: sectionCardBtnsFields
      },
    },
  };
};

export function PageHeaderHeroStatic(props) {
  let {
    design, headerBg, headerLogo, title, subtitle, btnList,
    specDesign, instance, history, match
  } = props;
  let { isMinBreakpointActive } = useBreakpoints();
  
  // let logoPath = specDesign.logo ? specDesign.logo[logoType] : null;
  // logoPath = _.isArray(logoPath) ? logoPath[0] : logoPath;
  // logoPath = getImageURL(logoPath, 'md', 'profilesImages', instance);
  // let logoSize = isMinBreakpointActive('lg') ? logoSizelg || 'xl' : logoSizeSm || 'md';

  let { logoType, logoPath, logoSize } = usePartLogo({
    ...props,
    specPart: headerLogo
  });

  const homePath = urlPrefix(match.params.instance, '/');

  return ( 
    <Background params={headerBg} classes={{ bgContainer: "snap-start" }}>
      {/* intro */}
      <div className="mx-auto font-brand-secondary container-width">
        {/* full*/}
        {design === 'full' ? (<div className="pt-6 px-14 pb-20 xs:pb-24 xs:pt-10 sm:px-10 text-center md:text-left">
          {/* logo */}
          {logoPath ? (
            <AnimationAddClassWhenVisible classToAdd="transition-opacity" classDefault="duration-1000 delay-[100ms]">
              <div className="mt-8 xl:mt-8 2xl:mt-14 3xl:mt-16 4xl:mt-20">
                <Link to={homePath}>
                  <PartLogo
                    classes={{ logoContainer: '!inline-block' }}
                    {...{
                      logoPath,
                      logoSize: logoSize || 'md',
                      logoStyle: logoType === 'profileImg' ? 'circle' : null,
                    }}
                  />
                </Link>
              </div>
            </AnimationAddClassWhenVisible>
          ) : null}
          {/* content*/}
          <div className={`
            mx-auto md:ml-0
            ${logoPath ? 'mt-8 xl:mt-12 2xl:mt-14' : 'mt-14 xl:mt-20 2xl:mt-24'}
            max-w-sm lg:max-w-md 2xl:max-w-2xl
          `}>
            <AnimationAddClassWhenVisible classToAdd="transition-opacity" classDefault="duration-1000 delay-[300ms]">
              <h1 className='mb-2 text-4xl xl:text-5xl 2xl:text-5xl text-brand-dark font-brand-main font-bold'>
                {lineBreaks(compileStringTemplate(title, specDesign.stringsVars))}
              </h1>
            </AnimationAddClassWhenVisible>
            <AnimationAddClassWhenVisible classToAdd="transition-opacity" classDefault="duration-1000 delay-[500ms]">
              <h2 className='text-2xl xl:text-3xl 2xl:text-3xl text-brand-dark/80 font-medium leading-snug'>
                {lineBreaks(compileStringTemplate(subtitle, specDesign.stringsVars))}
              </h2>
            </AnimationAddClassWhenVisible>
            <AnimationAddClassWhenVisible classToAdd="transition-opacity" classDefault="duration-1000 delay-[700ms]">
              <div className="w-fit flex flex-col lg:flex-row gap-2 mt-10 md:mt-4 xl:mt-6 2xl:mt-10 mx-auto md:ml-0">
                <BtnsList
                  {...props}
                  items={btnList}
                />
              </div>
            </AnimationAddClassWhenVisible>
          </div>
        </div>) : null}

        {/* mini*/}
        {design === 'mini' ? (<div className="pt-12 px-4 md:px-10 pb-12">
          <div className="flex flex-row gap-4 md:gap-8">
            {/* logo */}
            {logoPath ? (
              <AnimationAddClassWhenVisible classToAdd="transition-opacity" classDefault="duration-1000 delay-[100ms]">
                <div className="">
                  <Link to={homePath}>
                    <PartLogo
                      classes={{ logoContainer: '!inline-block' }}
                      {...{
                        logoPath,
                        logoSize: logoSize || 'md',
                        logoStyle: logoType === 'profileImg' ? 'circle' : null
                      }}
                    />
                  </Link>
                </div>
              </AnimationAddClassWhenVisible>
            ) : null}
            {/* content*/}
            <div className={`flex flex-col place-content-center`}>
              {title ? (
                <AnimationAddClassWhenVisible classToAdd="transition-opacity" classDefault="duration-1000 delay-[300ms]">
                  <h1 className='mb-2 text-xl md:text-xl lg:text-3xl xl:text-4xl text-brand-dark font-brand-main font-bold'>
                    {lineBreaks(compileStringTemplate(title, specDesign.stringsVars))}
                  </h1>
                </AnimationAddClassWhenVisible>
              ) : null}
              {subtitle ? (
                <AnimationAddClassWhenVisible classToAdd="transition-opacity" classDefault="duration-1000 delay-[500ms]">
                  <h2 className='text-base md:text-lg lg:text-2xl text-brand-dark/80 font-medium leading-snug'>
                    {lineBreaks(compileStringTemplate(subtitle, specDesign.stringsVars))}
                  </h2>
                </AnimationAddClassWhenVisible>
              ) : null}
              {btnList?.length && isMinBreakpointActive('lg') ? (
                <AnimationAddClassWhenVisible classToAdd="transition-opacity" classDefault="duration-1000 delay-[700ms]">
                  <div className="w-fit flex flex-col md:flex-row gap-2 mt-4 md:mt-6">
                    <BtnsList
                      {...props}
                      items={btnList}
                    />
                  </div>
                </AnimationAddClassWhenVisible>
              ) : null}
            </div>
          </div>
          {!isMinBreakpointActive('lg') ? (
            <div className="">
              <AnimationAddClassWhenVisible classToAdd="transition-opacity" classDefault="duration-1000 delay-[700ms]">
                <div className="w-fit flex flex-row gap-2 mt-4 md:mt-6">
                  <BtnsList
                    {...props}
                    items={btnList}
                  />
                </div>
              </AnimationAddClassWhenVisible>
            </div>
          ) : null}
        </div>) : null}
      </div>
    </Background>
  )
}