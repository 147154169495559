import _ from 'lodash';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import PartOfModule from '../../components/Module/PartOfModule';
import { EntityDocListView } from "../../components/EntityDocView";
import { getImageURL, stackClasses } from "../../libs/utils";
import { IonCard, IonCardContent, IonCardHeader } from '@ionic/react';
import { urlPrefix } from '../instance/utilsInstance';


export const viewClasses = {
  fieldContainer: "py-1 text-xs flex flex-col items-start",
  fieldLabel: "!inline-block text-normal text-xs text-gray-400 uppercase",
  fieldValue: "!inline-block mb-2 text-sm text-black"
};

export const viewInlineClasses = {
  fieldContainer: "py-1 mb-1 text-xs flex flex-row flex-wrap gap-0.5",
  fieldLabel: "pt-[3px] min-w-fit text-normal text-xs text-gray-500 uppercase",
  fieldValue: "text-sm text-black"
};

export const DocRow = (props) => {
  let {
    instance,
    doc,
    entitySpecs,
    UnderItem,
    only,
    exclude,
    outstandingOnly,
    showMainAttr = true
  } = props;

  if (!doc?.data) {
    return null;
  }

  let classes = stackClasses(props.classes, {
    fieldContainer: "py-1.5",
    fieldLabel: "text-black",
    fieldValue: "text-black"
  });

  return (
    <EntityDocListView
      instance={instance}
      {...entitySpecs}
      doc={doc}
      outstandingOnly={outstandingOnly}
      classes={classes}
      render={({ ViewData, taxonomyTypesDocList, mainAttr, mainImgAttr }) => (
        <tr className={classes.row}>
          <td className={`pb-0 ${classes.firstCol || classes.col}`}>
            {mainImgAttr && (doc?.data && doc.data[mainImgAttr] && doc.data[mainImgAttr].length) ? (
              <div className="flex flex-row mb-5">
                <div className=" w-[70px] shadow-md hover:shadow-xl">
                  <img src={doc?.data && doc.data[mainImgAttr] && doc.data[mainImgAttr].length && getImageURL(doc.data[mainImgAttr][0], 'xs')} 
                  className="rounded h-[70px] w-full object-cover" /> {/* image xs */}
                </div>
                {showMainAttr ? (
                  <div className="px-2 w-auto">
                    <ViewData field={mainAttr} classes={{ fieldLabel: "hidden", fieldContainer: 'text-2xl text-black font-semibold' }} />
                  </div>
                ) : null}
              </div>
            ) : (
              showMainAttr ? <ViewData field={mainAttr} classes={{ fieldLabel: "hidden", fieldContainer: 'text-2xl text-black font-semibold' }} /> : null
            )}

            {doc?.data?.deleted ? (
              <div className="-m-3 mb-2 py-3 text-xs text-center bg-red-500 text-white">
                <span className="uppercase font-bold">Registro eliminado</span>
                {doc?.data?.deletedDate ? (
                  <div className="text-xs">
                    Fecha: {dayjs(doc?.data?.deletedDate).format('lll')}
                  </div>
                ) : null}
              </div>
            ) : null}    
          </td>

          {taxonomyTypesDocList.map((taxonomyType) => (
            (mainAttr !== taxonomyType.data.nameSlug)
            && !(
              (only?.length && !only?.includes(taxonomyType.data.nameSlug))
              || (exclude?.length && exclude?.includes(taxonomyType.data.nameSlug))
            )
            ? (
              <td className={`${classes.col}`}>
                <ViewData key={taxonomyType.data.nameSlug} field={taxonomyType.data.nameSlug} classes={{ fieldLabel: "hidden", fieldContainer: '' }} />
              </td>
              // TODO: Agregar decorator y classes al TaxonomyType para View
            ) : null
          ))}

          {UnderItem ? (
            <td className={`${classes.col}`}>
              {UnderItem}
            </td>
          ) : null}
        </tr>
      )}
    />
  );
};

export const DocCard = ({ 
  instance, doc, entitySpecs, ExtraFields, UnderItem, only, exclude, outstandingOnly, 
  showMainAttr = true, showVisitDoc = true, design = "card", classes = viewClasses 
}) => {
  if (!doc?.data) return null;

  const renderMainData = (ViewData, mainAttr) => (
    showMainAttr && (
      <div className="px-2 w-auto">
        {showVisitDoc ? (
          <Link to={urlPrefix(instance, `/a/entity/${entitySpecs.entitySlug}/${doc.id}`)} className="block cursor-pointer">
            <ViewData field={mainAttr} classes={{ fieldLabel: "hidden", fieldContainer: 'text-2xl text-black font-semibold', fieldValue: 'underline' }} />
          </Link>
        ) : (
          <ViewData field={mainAttr} classes={{ fieldLabel: "hidden", fieldContainer: 'text-2xl text-black font-semibold' }} />
        )}
      </div>
    )
  );

  const renderContent = ({ ViewData, taxonomyTypesDocList, mainAttr, mainImgAttr }) => {
    const mainDataRendered = renderMainData(ViewData, mainAttr);
    const imgRendered = mainImgAttr && doc?.data[mainImgAttr]?.length && (
      <div className="flex flex-row mb-5">
        <div className="w-[70px] shadow-md hover:shadow-xl">
          <img src={getImageURL(doc.data[mainImgAttr][0], 'xs')} className="rounded h-[70px] w-full object-cover" />
        </div>
        {mainDataRendered}
      </div>
    );
    const deletedTag = doc?.data?.deleted && (
      <div className="-m-3 mb-2 py-3 text-xs text-center bg-red-500 text-white">
        <span className="uppercase font-bold">Registro eliminado</span>
        {doc?.data?.deletedDate && <div className="text-xs">Fecha: {dayjs(doc.data.deletedDate).format('lll')}</div>}
      </div>
    );
    
    const renderFields = taxonomyTypesDocList.map(({ data: { nameSlug } }) =>
      mainAttr !== nameSlug && (!only?.length || only.includes(nameSlug)) && (!exclude?.length || !exclude.includes(nameSlug)) && (
        <ViewData key={nameSlug} field={nameSlug} />
      )
    );

    const content = (
      <>
        {imgRendered || mainDataRendered}
        {deletedTag}
        {renderFields}
        {ExtraFields && <ExtraFields {...{ doc, ViewData, mainAttr, mainImgAttr, classes }} />}
      </>
    );

    return design === "card" ? (
      <IonCard className={`m-0 ${classes.ionCard}`}>
        <IonCardHeader className="pb-0">{content}</IonCardHeader>
      </IonCard>
    ) : (
      <div className={`${classes.flatCard}`}>{content}</div>
    );
  };

  return (
    <>
      <EntityDocListView
        instance={instance}
        {...entitySpecs}
        doc={doc}
        outstandingOnly={outstandingOnly}
        classes={classes}
        render={renderContent}
      />
      {UnderItem}
    </>
  );
};


export const useListDoc = (propsA) => (propsB) => {
  let {
    entitySpecs,
    isAllowed,
    ExtraFields,
    outstandingOnly = true,
    // classes
  } = propsA;
  let {
    doc,
    routerLink,
    isSelected,
    onClick,
    // classes
    only,
    exclude
  } = propsB;
  
  const { entitySlug, taxonomyTypesDocList } = entitySpecs || {};

  let classes = stackClasses(propsB.classes, propsA.classes);
  
  return (
    <EntityDocListView
      {...entitySpecs}
      only={only}
      exclude={exclude}
      doc={doc}
      outstandingOnly={outstandingOnly}
      viewType="list"
      classes={{
        fieldContainer: "py-1 text-xs flex items-center",
        fieldLabel: "!inline-block text-black",
        fieldValue: "!inline-block text-black",
        ...classes
      }}
      render={({ ViewData, mainAttr, mainImgAttr, classes }) => (
        <div onClick={onClick} className={`flex flex-row w-full gap-2 ${isSelected ? 'text-brand-primary' : ''} ${onClick ? 'cursor-pointer' : ''} `}>
          {mainImgAttr && (doc?.data && doc.data[mainImgAttr] && doc.data[mainImgAttr].length) ? (
            <img src={doc?.data && doc.data[mainImgAttr] && doc.data[mainImgAttr].length && getImageURL(doc.data[mainImgAttr][0], 'xs')} 
            className={`rounded h-[70px] w-[70px] object-cover ${classes.img}`} />
          ) : null}
          <div>
            {routerLink ? (
              <Link to={routerLink} className="block cursor-pointer">
                <ViewData field={mainAttr} classes={{ fieldContainer: `-mb-0.5 underline text-base leading-tight font-semibold ${isSelected ? 'text-brand-primary' : ''}` }} />
              </Link>
            ) : (
              <ViewData field={mainAttr} classes={{ fieldContainer: `-mb-0.5 text-base leading-tight font-semibold ${isSelected ? 'text-brand-primary' : ''}` }} />
            )}

            {taxonomyTypesDocList.map((taxonomyType) => (
              (![mainAttr, mainImgAttr].includes(taxonomyType.data.nameSlug)) ? (
                <ViewData key={taxonomyType.data.nameSlug} field={taxonomyType.data.nameSlug} classes={{ fieldContainer: `text-xs font-normal` }} />
                // TODO: Agregar decorator y classes al TaxonomyType para View
              ) : null
            ))}

            <PartOfModule
              type="crud"
              action="UnderListItemTitle"
              entitySlug={entitySlug}
              param={{ doc, ViewData, mainAttr, mainImgAttr, isAllowed, entitySlug }} 
            />
            {ExtraFields ? (
              <ExtraFields {...{ doc, ViewData, mainAttr, mainImgAttr, isAllowed, entitySlug, classes }} />
            ) : null}
          </div>
          <PartOfModule
            type="crud"
            action="UnderListItemHeader"
            entitySlug={entitySlug}
            param={{ doc, ViewData, mainAttr, mainImgAttr, isAllowed, entitySlug }} 
          />
        </div>
      )}
    />
  );
};