import _ from 'lodash';
import { Fragment } from "react";


export const blocksMap = {};
export const blocksDefinitions = {};
export const partsMap = {};

export const getBlockObjects = () => {
  return { blocksMap, blocksDefinitions };
};

export const registerBlocks = (blocks) => {
  blocks.forEach((blockGetDef) => {
    const blockDef = blockGetDef();
    if (!blocksMap[blockDef.type]) {
      blocksMap[blockDef.type] = {};
    }
    blocksMap[blockDef.type][blockDef.variant] = blockDef;
  });
};

export const registerBlockDefinitions = (definitions) => {
  _.forEach(definitions, (definitionDef, type) => {
    blocksDefinitions[type] = definitionDef;
  });
};

export const registerParts = (parts) => {
  parts.forEach((partGetDef) => {
    const partDef = partGetDef();
    partsMap[partDef.type] = partDef;
  });
};

const BlockStack = (props) => {
  let {
    specDesign,
    specStack,
    staticContext,
    ...propsToUse
  } = props;

  let rendered = specStack.map(({ type, variant, strings={}, template, ...params }, index) => {
    if (template) {
      return (
        <Fragment key={index}>
          {template({
            id: index,
            strings,
            ...strings,
            ...propsToUse,
            ...params,
            specDesign
          })}
        </Fragment>
      );
    }
    
    if (!blocksMap[type] || !blocksMap[type][variant]) {
      return null;
    }

    const blockDef = blocksMap[type][variant];
    if (!blockDef) {
      throw new Error(`The block ${type} ${variant} is not defined`);
    }

    let paramsValues = { ...params };
    _.forEach(blockDef.params, ({ type, defaults }, field) => {
      if (!params?.hasOwnProperty(field)) {
        paramsValues[field] = defaults;
      }
    });

    return (
      <Fragment key={index}>
        <blockDef.template 
          {...props}
          strings={strings}
          {...strings}
          {...propsToUse}
          {...paramsValues}
          specDesign={specDesign}
          id={index}
        />
      </Fragment>
    );
  });

  return rendered;
};

export default BlockStack;