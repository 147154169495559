import { useEffect, useMemo, useState } from 'react';
import _ from 'lodash';
import { nanoid } from 'nanoid';
import {
  IonList,
  IonButton,
  IonIcon,
  IonLabel,
  IonCard,
  IonCardHeader,
  IonCardContent,
  IonToolbar,
  IonButtons,
} from '@ionic/react';
import { arrowUp, arrowDown, close, pencil, eye } from 'ionicons/icons';
import IonBtnLoading from '../ui/IonBtnLoading';
import { EntityTaxonomyForm, inputClasses } from '../EntityTaxonomyForm';
import ObjectShow from '../../modules/entity/ObjectShow';

// Componente que muestra el formulario dentro de un IonCard
const ItemForm = (props) => {
  const { instance, entitySlug, fields, name, index } = props;

  const fieldsPrefixed = useMemo(() => {
    return _.map(fields, (field) => ({
      ...field,
      fieldName: `${name}[${index}][${field.fieldName}]`,
    }));
  }, [fields]);

  return (
    <EntityTaxonomyForm
      instance={instance}
      entitySlug={entitySlug}
      taxonomyTypesDataList={fieldsPrefixed}
      classes={inputClasses}
    />
  );
};

const RawInputCollectionForm = (props) => {
  const { instance, entitySlug, value, onChange, fields, name } = props;
  const [items, setItems] = useState(value);
  const [editingIndex, setEditingIndex] = useState(null); // Controla qué ítem se está editando
  const [listId] = useState(nanoid());

  useEffect(() => {
    setItems(value);
  }, [value]);

  const handleAddItem = () => {
    const newItem = {};
    const newItems = [...(items || []), newItem];
    setItems(newItems);
    onChange(newItems);
  };

  const handleItemDelete = (itemToDelete) => {
    const updatedItems = items.filter((item) => item !== itemToDelete);
    setItems(updatedItems);
    onChange(updatedItems);
  };

  const moveUp = (item) => {
    const newOrder = [...items];
    const originalIndex = newOrder.indexOf(item);
    if (originalIndex > 0) {
      [newOrder[originalIndex], newOrder[originalIndex - 1]] = [
        newOrder[originalIndex - 1],
        newOrder[originalIndex],
      ];
      setItems(newOrder);
      onChange(newOrder);
    }
  };

  const moveDown = (item) => {
    const newOrder = [...items];
    const originalIndex = newOrder.indexOf(item);
    if (originalIndex < newOrder.length - 1) {
      [newOrder[originalIndex], newOrder[originalIndex + 1]] = [
        newOrder[originalIndex + 1],
        newOrder[originalIndex],
      ];
      setItems(newOrder);
      onChange(newOrder);
    }
  };

  return (
    <div>
      {/* Lista de items */}
      {items && items.map((item, index) => (
        <IonCard
          className="w-full mx-0"
          key={`${listId}-${index}`}
        >
          <IonCardHeader color={editingIndex === index ? 'medium' : undefined}>
            <IonToolbar color={editingIndex === index ? 'medium' : undefined}>
              <IonLabel slot="start">#{index + 1}</IonLabel>

              <IonButtons slot="end" className="flex space-x-2">
                {editingIndex === index ? (
                  <IonButton onClick={() => setEditingIndex(null)} fill="clear">
                    <IonIcon icon={eye} /> {/* Cambiar a icono de "ver" */}
                  </IonButton>
                ) : (
                  <IonButton onClick={() => setEditingIndex(index)} fill="clear">
                    <IonIcon icon={pencil} /> {/* Icono de "editar" */}
                  </IonButton>
                )}
                <IonButton onClick={() => moveUp(item)} fill="clear">
                  <IonIcon icon={arrowUp} />
                </IonButton>
                <IonButton onClick={() => moveDown(item)} fill="clear">
                  <IonIcon icon={arrowDown} />
                </IonButton>
                <IonButton onClick={() => handleItemDelete(item)} color="danger" fill="clear">
                  <IonIcon icon={close} />
                </IonButton>
              </IonButtons>
            </IonToolbar>
          </IonCardHeader>

          <IonCardContent>
            {editingIndex === index ? (
              <div className="mt-2">
                <ItemForm
                  instance={instance}
                  entitySlug={entitySlug}
                  name={name}
                  fields={fields}
                  index={index}
                />
              </div>
            ) : (
              <ObjectShow
                instance={instance}
                entitySlug={entitySlug}
                value={item}
                fields={fields}
                design="flat"
              />
            )}
          </IonCardContent>
        </IonCard>
      ))}

      {/* Botón para agregar nuevo item */}
      <div className="space-x-2 flex items-center">
        <IonLabel className="uppercase inline-block text-xs">Agregar</IonLabel>
        <IonBtnLoading label="Nuevo" onClick={handleAddItem} color="secondary" size="small" fill="solid" />
      </div>
    </div>
  );
};

export default RawInputCollectionForm;
