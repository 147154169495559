import _ from 'lodash';
import { LayoutAdmin } from "../panel/LayoutAdmin";
import { useEffect, useState } from 'react';
import { InstanceInlineSelectorForMain } from '../instance/InstanceSelector';
import { usePanel } from '../panel/usePanel';
import IonBtnLoading from '../../components/ui/IonBtnLoading';
import { getJson } from '../../libs/utils';
import Model from '../../libs/ModelClass';
import { withPrefix } from '../instance/utilsInstance';
import { getConfig } from '../../config';
import toast from 'react-hot-toast';


export function Content(props) {
  let {
    selectedInstance,
    attachPrefix
  } = props;

  const syncData = async () => {
    try {
      const contractsDocsStatic = await getJson('/data/sales/contractsModels.json');
      if (contractsDocsStatic?.length) {
        const ContractModelModel = Model.extend(withPrefix('main', getConfig().modules.sales.contractsModelsEntitySlug));
        for (const docStatic of contractsDocsStatic) {
          await ContractModelModel.createOrUpdate(docStatic);
        }
      }
      toast.success('Contratos actualizados');
    } catch (error) {
      toast.error(error.message);
      console.error(error);
    }
  };

  return (
    <div className="p-4">

      {/* <IonBtnLoading
        label="Sincroniar contratos"
        onClickAsync={syncData}
      /> */}

    </div>
  );
}

export function RouteEntityUpdates(props) {
  const { selectedInstance, attachPrefix } = usePanel();
  const [ selectedInner, setSelectedInner ] = useState(selectedInstance);

  useEffect(() =>{
    setSelectedInner(selectedInstance);
  }, [selectedInstance]);

  return (
    <LayoutAdmin 
      history={props.history}
      breadcrumbs={[{
        title: "Actualizaciones de datos"
      }]}
      TitleToolbarRight={() => (
        <InstanceInlineSelectorForMain {...props} selectedInstance={selectedInner} setSelectedInstance={setSelectedInner} design="labelInsideBtn" />
      )}
    >
      <Content {...props} selectedInstance={selectedInner} attachPrefix={attachPrefix} />
    </LayoutAdmin>
  );
}
