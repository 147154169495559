import _ from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useAsyncMemo } from "use-async-memo";
import { LayoutAdmin } from "../panel/LayoutAdmin";
import SectionCrudForm from "../../components/SectionCrudForm";
import Model, { useStateSingleResult } from "../../libs/ModelClass";
import useEntityTaxonomyForm, { inputClasses } from '../../components/EntityTaxonomyForm';
import PartOfModule, { usePartOfModule } from '../../components/Module/PartOfModule';
import { useModule } from '../../libs/ModuleContext';
import toast from 'react-hot-toast';
import { useModuleSettings } from '.';
import BadgeLoading from '../../components/ui/BadgeLoading';
import { usePanel } from '../panel/usePanel';
import { useCanAddMoreDocs } from './MaxCountLimitAlert';


export function EntityCrudForm(props) {
  let {
    context,
    entityDoc,
    history,
    match,
    attachPrefix,
    defaultValue,
    asModal,
    classes = {}
  } = props;
  
  const entitySlug = props.entitySlug || context?.entitySlug || entityDoc?.data?.nameSlug || match.params.entitySlug;
  const docId = context?.docId || props.docId || match.params.docId;
  const isEdit = docId !== 'new';
  const ExtendedModel = Model.extend(entitySlug);
  const { fireEventReducer, isAllowed } = useModule();
  const { selectedInstance } = usePanel();
  const instanceDoc = context?.instanceDoc || selectedInstance;
  const instance = props.instance || instanceDoc?.data?.hash || 'main';
  const { moduleSettings } = useModuleSettings({ entitySlug });
  const [ fieldsRequired, setFieldsRequired ] = useState([]);
  const { taxonomyTypesDocList, EntityTaxonomyForm } = useEntityTaxonomyForm({ 
    instance,
    entityId: entityDoc?.id,
    entitySlug,
    fieldsRequired, 
    setFieldsRequired
  });
  const canAddMoreDocs = useCanAddMoreDocs(entitySlug, moduleSettings, selectedInstance);
  const [ firedAfterRead, setFiredAfterRead ] = useState(false);
  const [ parsedDoc, setParsedDoc ] = useState();
  let FormAppendColumnRight = usePartOfModule({
    type: "crud",
    action: "FormAppendColumnRight",
    entitySlug,
    // param: { values: formProps.values, doc: parsedDoc, instance, entityDoc, entitySlug, taxonomyTypesDocList, isAllowed }
  });
  const showColumnRight = FormAppendColumnRight || moduleSettings.columnRightTaxonomyTypesSlugs?.length;
  const onClose = context?.onClose || props.onClose;

  const doc = useAsyncMemo(async () => {
    let doc = props.doc || await ExtendedModel.findById(docId);
    if (!doc) {
      return new ExtendedModel({ ...defaultValue });
    }
    return doc;
  }, [props.doc, docId]);

  useEffect(() => {
    fireAfterRead(doc, true);
  }, [doc, taxonomyTypesDocList]);

  const fireAfterRead = async (doc, force = false) => {
    if ((force || !firedAfterRead) && doc && taxonomyTypesDocList?.length) {
      const modifiedDocData = await fireEventReducer('afterRead', { doc, isEdit, instance, entitySlug, taxonomyTypesDocList, instanceDoc, entityDoc }, doc.data);  
      doc.data = modifiedDocData;
      setFiredAfterRead(true);
      setParsedDoc(doc);
    }
  }

  const handleSave = async (formValues) => {
    try {
      let modifiedFormValues = await fireEventReducer('beforeSave', { doc, isEdit, instance, entitySlug, taxonomyTypesDocList, instanceDoc, entityDoc }, formValues);
      // assign instanceId for global entities
      if (instanceDoc && !entityDoc?.data.isInstance && instanceDoc?.data.hash !== 'main') {
        modifiedFormValues.instanceId = instanceDoc.id;
      }
      if (context?.onBeforeSave) {
        await context.onBeforeSave(modifiedFormValues);
      }
      const savedItem = await ExtendedModel.createOrUpdate(modifiedFormValues);
      await fireEventReducer('afterSave', { doc: savedItem, formValues, modifiedFormValues, isEdit, instance, entitySlug, taxonomyTypesDocList, instanceDoc, entityDoc }, savedItem);
      if (context?.onAfterSave) {
        return context?.onAfterSave(savedItem);
      }
      if (moduleSettings.redirectOnSave) {
        history.push(attachPrefix(`/a/entity/${entityDoc?.data?.nameSlug}/${savedItem.id}`));
      }
      else {
        fireAfterRead(savedItem, true);
      }
    } catch (error) {
      console.error(error);
      toast.error(error?.message || error?.code);
    }
  };

  const onValidation = (values, errors) => {
    // all required fields
    fieldsRequired.forEach(field => {
      if (!values[field]) {
        errors[field] = ' '; 
      }
    });
    // [TODO] realiar validación de taxonomías segú tipo y param
  };

  const FormInputFields = ((formProps) => (<>
    <PartOfModule
      type="crud"
      action="FormPrepend"
      entitySlug={entitySlug}
      param={{ values: formProps.values, doc: parsedDoc, instance, entityDoc, entitySlug, taxonomyTypesDocList, isAllowed, history, match, formApi: formProps.form }} 
    />

    <div className={`${FormAppendColumnRight?.classes?.container || ''} ${showColumnRight ? `flex flex-col md:flex-row gap-2 md:gap-4` : ''}`}>
      <div className={FormAppendColumnRight?.classes?.firstCol || ''}>
        <EntityTaxonomyForm
          {...props}
          {...formProps}
          classes={{
            ...classes,
            ...inputClasses
          }}
          overrideParams={{
            showLabel: true,
            showClearBtn: true,
          }}
          entitySlug={entitySlug}
        />
      </div>
      {showColumnRight ? (
        <div className={FormAppendColumnRight?.classes?.secondCol || ''}>
          {FormAppendColumnRight?.Render ? (
            <FormAppendColumnRight.Render {...{ values: formProps.values, doc: parsedDoc, instance, entityDoc, entitySlug, taxonomyTypesDocList, isAllowed, history, match, formApi: formProps.form }} /> 
          ) : null}
          { moduleSettings.columnRightTaxonomyTypesSlugs?.length ? (
            <EntityTaxonomyForm
              {...props}
              {...formProps}
              classes={{
                ...classes,
                ...inputClasses
              }}
              overrideParams={{
                showLabel: true,
                showClearBtn: true,
              }}
              entitySlug={entitySlug}
            />
          ) : null}
        </div>
      ) : null }
    </div>
    
    <PartOfModule
      type="crud"
      action="FormAppend"
      entitySlug={entitySlug}
      param={{ values: formProps.values, doc: parsedDoc, instance, entityDoc, entitySlug, taxonomyTypesDocList, isAllowed, history, match, formApi: formProps.form }} 
    />
  </>));

  const Content = useMemo(() => {
    return () => (
      <>
        {/* Entity Form */}
        {parsedDoc ? (<>
          <SectionCrudForm
            {...props}
            instance={instance}
            entitySlug={entitySlug}
            entityDoc={entityDoc}
            doc={parsedDoc}
            onSave={handleSave}
            onValidation={onValidation}
            fieldsRequired={fieldsRequired}
            FormInputFields={FormInputFields}
            editStyle={asModal ? 'modal' : "route"}
            showTitle={true}
            showSaveButton={docId === 'new' ? canAddMoreDocs?.canAddMoreDocs : true}
            defaultValue={defaultValue}
            onClose={onClose}
          />
          <PartOfModule
            type="crud"
            action="UnderForm"
            entitySlug={entitySlug}
            param={{ doc: parsedDoc, instance, entityDoc, entitySlug, taxonomyTypesDocList, isAllowed, history, match }} 
          />
        </>) : null}
      </>
    );
  }, [parsedDoc, canAddMoreDocs?.canAddMoreDocs]);

  if (!parsedDoc) {
    return (
      <div className={`pt-32 flex place-content-center content-center items-center font-brand-main ${classes?.loadingContainer}`}>
        <BadgeLoading className="text-brand-dark" />
      </div>
    );
  }

  return <Content />;
}

export function RouteEntityCrudForm(props) {
  let {
    context,
    withLayout = true,
    match,
    history,
    location,
    showInstanceSelector = true,
    TitleToolbarRight,
    selectedInstance,
    classes,
    ContentRender
  } = props;
  const panelLibs = usePanel();
  const { attachPrefix } = panelLibs;
  const entitySlug = props.entitySlug || context?.entitySlug || match.params.entitySlug;
  const docId = context?.docId || props.docId || match.params.docId;
  const entityDoc = useStateSingleResult({
    Model: Model.extend('entities'), 
    nameSlug: entitySlug
  });
  const selectedInner = useMemo(() => (
    selectedInstance || panelLibs?.selectedInstance
  ), [selectedInstance, panelLibs?.selectedInstance]);
  
  // initial value
  let defaultValue = context?.defaultValue || props.defaultValue;

  // presenting style
  let asModal = context?.asModal || props.asModal;
  withLayout = withLayout && !asModal;

  const listUrl = attachPrefix('/a/entity/' + entityDoc?.data.nameSlug + '/list');
  const breadcrumbs = context?.breadcrumbs || [
    {
      url: listUrl,
      title: entityDoc?.data?.name
    },
    {
      title: "Formulario"
    }
  ];

  // attach instanceId to global entities
  if (!entityDoc?.data.isInstance && selectedInner?.data.hash !== 'main') {
    defaultValue = {
      instanceId: selectedInner?.id,
      ...(defaultValue || {})
    };
  }

  ContentRender = ContentRender || context?.ContentRender || EntityCrudForm;

  // if (!entityDoc?.id || !selectedInner?.id) {
  //   return (
  //     <div className={`pt-32 flex place-content-center content-center items-center font-brand-main ${classes?.loadingContainer}`}>
  //       <BadgeLoading className="text-brand-dark" />
  //     </div>
  //   );
  // }

  const content = (
    <ContentRender
      {...props}
      entityDoc={entityDoc}
      attachPrefix={attachPrefix}
      docId={docId}
      defaultValue={defaultValue}
      asModal={asModal}
    />
  );

  if (!withLayout) {
    return content;
  }

  return (
    <LayoutAdmin 
      history={history} 
      defaultHref={context?.defaultBackRoute || listUrl}
      breadcrumbs={breadcrumbs}
      TitleToolbarRight={TitleToolbarRight}
    >
      {content}
    </LayoutAdmin>
  );
}
