import { routePageStack } from "../layout/RouteLayoutBlock";
import { specDesign, stringsMapLayoutBlocks } from "../specSite";
import { CustomHeader } from "./CustomBlocks/BlockPageHeaderAHero";
import { trendingUpOutline, starOutline } from "ionicons/icons";
import { TbPigMoney } from "react-icons/tb";


export const blocks = {
  headerHero: {
    title: "Presenta\ntus productos\ncon nuestro\ncatálogo virtual",
    subtitle: "La herramienta ideal\npara gestionar y\nmostrar tu inventario",
    btnList: [
      {
        ctaLabel: "Acceder",
        ctaType: "signup",
        ctaSignUp: {
          ctaLabel: "Acceder",
          modalTitle: "Crea tu catálogo",
          modalContent: `
### Es fácil!

- Inicia sesión con Gmail
- Completa tus datos de usuario
- Personaliza tu catálogo
- Carga tus productos y servicios
- ¡Listo!

      `
        },
        btnSize: "medium",
        btnColor: "light",
        btnFill: "solid"
      },
      // {
      //   ctaLabel: "Contactar",
      //   ctaType: "whatsapp",
      //   ctaWhatsapp: {
      //     phoneMessage: 'Hola Tranqui Pro! quiero más información sobre el Catálogo Virtual',
      //     useBrandPhone: true
      //   },
      //   btnSize: "medium",
      //   btnColor: "light",
      //   btnFill: "solid"
      // }
    ]
    // ctaLabel: "Crear catálogo",
    // ctaType: "signup",
    // ...stringsMapLayoutBlocks.modalSignUp
  },
  // beneficios del producto
  textGridWithTitle: {
    items: [
      {
        ionIcon: trendingUpOutline,
        title: "Oportunidades\nde venta",
        content: "Organiza y haz accesibles tus productos para maximizar las oportunidades de negocio",
      },
      {
        ionIcon: starOutline,
        title: "Ofrece una\nexperiencia moderna",
        content: "Impresiona a los clientes con un catálogo digital interactivo y profesional",
      },
      {
        faIcon: TbPigMoney,
        title: "Ahorra tiempo y recursos",
        content: "Centraliza todas las fotos en un solo lugar para facilitar la gestión y visualización para el negocio y los clientes",
      },      
    ],
  },  
  // características del producto
  textGridExtendedWithImages: {
    items: [
      {
        title: "Ofrece una experiencia de compra personalizada",
        subtitle: "Facilita a los clientes encontrar lo que necesitan",
        content: "Nuestro catálogo está equipado con filtros por categoría, color, tamaño y más, para que los clientes puedan explorar y encontrar fácilmente los productos que desean",
        ctaTitle: "Explorar catálogo",
        images: [{
          src: "/images/filtros/filtros.png"
        }, {
          src: "/images/filtros/queens-02.png"
        }, {
          src: "/images/filtros/queens-03.png"
        }, {
          src: "/images/filtros/queens-04.png"
        }]
      },
      {
        title: "Destaca la calidad de los productos",
        subtitle: "Muestra cada detalle de manera impresionante",
        content: "Cada producto cuenta con su propia sección independiente, con una galería de fotos en alta resolución que permite a los clientes ver todas las variaciones y detalles de los productos",
        ctaTitle: "Ver productos",
        images: [{
          src: "/images/view-item/item-01.png"
        // }, {
        //   src: "/images/view-item/item-02.png"
        }]
      },
      // {
      //   title: "Simplifica la gestión de pedidos",
      //   subtitle: "Optimiza los procesos de ventas",
      //   content: "Con nuestra solución, los clientes pueden finalizar sus pedidos directamente a través de WhatsApp. Al completar la compra, notificarán al vendedor por WhatsApp, lo que agiliza y simplifica el proceso de ventas",
      //   ctaTitle: "Obtener más información",
      // }
    ],
  },
  
  customerReviews: {
    title: "Lo que dicen nuestros clientes",
    subtitle: "Testimonios reales de empresarios que han transformado su negocio con nuestro Catálogo Virtual",
    items: [
      {
        title: "Eficiencia en Inventario",
        content: "Con el Catálogo Virtual, gestionar mi inventario es ahora más fácil y eficiente, lo que ha llevado a un aumento significativo en mis ventas"
      },
      {
        title: "Proceso de Ventas Simplificado",
        content: "El Catálogo Virtual ha simplificado mi proceso de ventas, permitiéndome presentar mis productos de manera atractiva y profesional a mis clientes"
      },
      {
        title: "Acceso desde Cualquier Dispositivo",
        content: "La adaptabilidad del Catálogo Virtual a dispositivos móviles ha facilitado que mis clientes puedan consultar mi catálogo y hacer pedidos desde cualquier lugar y en cualquier momento"
      },       
      {
        title: "Variedad de Productos",
        content: "Gracias al Catálogo Virtual, puedo mostrar mi variedad de productos de manera organizada y atractiva, lo que ha contribuido a aumentar mis ventas"
      },
    ],    
  },

  // Portfolio
  portfolio: {
    title: "Nuestro Portafolio",
    subtitle: "Descubre algunos de nuestros proyectos destacados",
    // ctaLabel: "Únete a la Red",
    // ctaType: "signup",
    ctaLabel: "Contactar",
    ctaType: "whatsapp",
    ...stringsMapLayoutBlocks.modalSignUp,
    items: [
      {
        title: "Web Institucional",
        content: "Destaca las empresas de tus clientes y convierte visitantes en clientes con nuestra solución de Web Institucional de ventas, moderna y eficaz",
        ctaLink: "/m/page/web-institucional",
        image: "/images/landing-thumb-02.png"
      },
      {
        title: "Catálogo Virtual",
        content: "Facilita que tus clientes presenten sus productos de forma efectiva, gestionen pedidos de manera sencilla y ordenada, todo con filtros personalizables",
        ctaLink: "/m/page/catalogo",
        image: "/images/catalogo-thumb.png"
      }
    ]
  },

  frequentAnswers: {
    title: "Preguntas frecuentes",
    items: [
      {
        title: "¿Cómo puedo ofrecer el Catálogo Virtual a mis clientes?",
        content: "¡Es sencillo! Únete a nuestra red como colaborador y podrás ofrecer el Catálogo Virtual a tus clientes sin recargo de precio, agregando valor a tus servicios",
      },
      {
        title: "¿Qué dispositivos pueden utilizar el Catálogo Virtual?",
        content: "El Catálogo Virtual es compatible con todos los dispositivos modernos, incluyendo computadoras de escritorio, tablets y smartphones, permitiendo a tus clientes acceder desde cualquier lugar",
      },
      {
        title: "¿Ofrecen soporte técnico para mis clientes?",
        content: "Sí, nuestro equipo de soporte técnico está disponible para ayudarte a resolver cualquier duda o problema que puedas tener, garantizando la satisfacción de tus clientes",
      },
      {
        title: "¿Puedo personalizar el Catálogo Virtual según las necesidades de mis clientes?",
        content: "¡Por supuesto! Podemos adaptar el diseño y las funcionalidades del Catálogo Virtual para satisfacer las necesidades específicas de tus clientes, ofreciendo soluciones personalizadas",
      },
      {
        title: "¿Cómo gestionan la seguridad y privacidad de los datos de mis clientes?",
        content: "Nos comprometemos a implementar medidas robustas de seguridad y privacidad para proteger los datos de tus clientes, cumpliendo estándares de seguridad y garantizando la confidencialidad de la información"
      },
      {
        title: "¿Qué requisitos necesito cumplir para unirme a la red de colaboradores de Tranqui Pro?",
        content: "Para unirte a nuestra red de colaboradores, necesitas ser una agencia o profesional autónomo con experiencia en tecnología y estar dispuesto a ofrecer soluciones de calidad a tus clientes, siguiendo nuestros estándares y procesos establecidos"
      },
    ]    
  },
  // ingresar
  ctaSignUp: {
    title: "Únete a Nuestra Red de Colaboradores",
    subtitle: "Descubre cómo puedes formar parte de nuestra comunidad y beneficiarte de nuestras soluciones tecnológicas",
    btnList: [
      {
        ctaLabel: "Contactar",
        ctaType: "whatsapp",
        ctaWhatsapp: {
          phoneMessage: 'Me interesa saber más sobre la red...',
          useBrandPhone: true
        },
        btnSize: "medium",
        btnColor: "light",
        btnFill: "solid"
      }
    ]
    // ctaType: "signup",
    // ctaLabel: "Unirme y crear catálogo",
    // ...stringsMapLayoutBlocks.modalSignUp
  },
  // footer
  footer: {
    ...stringsMapLayoutBlocks.footer,
    parts: {
      ctaFloat: {
        ctaLabel: "Contactar",
        ctaType: "whatsapp",
        // ctaType: "signup",
        // ctaLabel: "Crear catálogo",
        // ...stringsMapLayoutBlocks.modalSignUp,
      }
    }
  }
};

const specStack = [
  // {
  //   // custom block example
  //   strings: blocks.headerHero,
  //   template: HeaderHeroStaticBlock
  // },
  {
    type: 'pageHeader',
    variant: 'heroStatic',
    strings: blocks.headerHero,
    template: CustomHeader,
    params: {
      mainBg: {
        type: 'gradient',
        bgColorDirection: 'to bottom right',
        bgColorFrom: '#211cf4', 
        bgColorTo: '#1ac3ff',
        // classes: {
        //   bgContainer: 'h-96'
        // }
      }
    }
  },
  {
    type: 'textGrid',
    variant: 'compact',
    strings: blocks.textGridWithTitle
  },
  {
    type: 'textGrid',
    variant: 'extendedCarousel',
    strings: blocks.textGridExtendedWithImages
  },
  {
    type: 'cta',
    variant: 'hero',
    strings: blocks.ctaSignUp
  },
  {
    type: 'reviews',
    variant: 'grid',
    strings: blocks.customerReviews
  },
  // {
  //   type: 'ctaMenu',
  //   variant: 'grid',
  //   strings: blocks.portfolio
  // },
  {
    type: 'frecuentAnswers',
    variant: 'columnToggle',
    strings: blocks.frequentAnswers
  },
  {
    type: 'pageFooter',
    variant: 'linksFixedCTA',
    strings: blocks.footer,
    // params: { showCTA: false }
  },
];

export const RouteCatalogoFree = routePageStack({ specDesign, specStack });