import _ from 'lodash';
import { IonButton, IonButtons, IonIcon } from '@ionic/react';
import { useLocation } from 'react-router-dom';
import { useEffect, useMemo, useState } from 'react';
import { useEntityFullBySlug } from './Entity';
import { 
  cog
} from 'ionicons/icons';
import { LayoutAdmin } from "../panel/LayoutAdmin";
import SectionCrudModel from "../../components/SectionCrudModel";
import Model, { useStateSingleResult } from "../../libs/ModelClass";
import PartOfModule from '../../components/Module/PartOfModule';
import { useModule } from "../../libs/ModuleContext";
import { useModuleSettings } from '.';
import { useListDoc } from './docCardsVariants';
import { parseAttrValParams } from '../../libs/utils';
import BadgeLoading from '../../components/ui/BadgeLoading';
import { usePanel } from '../panel/usePanel';
import { InstanceInlineSelectorForMain } from '../instance/InstanceSelector';
import { useCanAddMoreDocs } from './MaxCountLimitAlert';
// import { useFilterPagination } from './useFilterPagination';


// TODO
// Mostrar/Ocultar botón ADD
// Mostrar barra de filtros
export function EntityCrudList(props) {  
  let {
    context,
    selectedInstance,
    entityDoc,
    history,
    match,
    parsedParams,
    navigateTo,
    fetchItems,
    attachPrefix,
    filterMenuSlug,
    showBtnShow = true,
    showBtnCrud = true,
    ExtraFields,
    EmptyContent,
    forceAllowed = false
  } = props;
  const { isAllowed } = useModule();
  const { contractModelMetaData } = usePanel();
  const entitySlug = entityDoc?.data?.nameSlug;
  const ExtendedModel = Model.extend(entitySlug);
  const location = useLocation();
  const [ refresh, doRefresh ] = useState(0);
  const entitySpecs = useEntityFullBySlug({ entitySlug, entityDoc, filterMenuSlug });
  const ListDoc = useListDoc({ entitySpecs, isAllowed, ExtraFields });
  const { moduleSettings } = useModuleSettings({ entitySlug, contractModelMetaData });
  const canAddMoreDocs = useCanAddMoreDocs(entitySlug, moduleSettings, selectedInstance, refresh);

  useEffect(() => {
    doRefresh(refresh + 1);
  }, [location]);
  
  const validateForm = () => {};
  const handleBeforeSave = () => {};
  const handleDelete = () => {};

  const hardFilter = props.hardFilter || context?.hardFilter || (
    async(query) => {
      // hard filter for main instance with instance hash as prefix
      if (!entityDoc?.data.isInstance && selectedInstance?.id && selectedInstance?.data.hash !== 'main') {
        query['instanceId'] = selectedInstance.id;
      }
      return query;
    }
  );
  
  const ExtraActions = () => (<>
    <PartOfModule
      type="crud"
      action="ListHeaderExtraActions"
      entitySlug={entitySlug}
      param={{ Model, isAllowed, canAddMoreDocs, entitySlug, entityDoc, location, entitySpecs, moduleSettings }}
    />
    {(selectedInstance?.id && selectedInstance?.data.hash === 'main') && isAllowed('panel', ['tab:config']) ? (
      <IonButtons slot="end">
        <IonButton
          routerLink={attachPrefix(`/a/entityCreator/${entityDoc?.data.id}/form`)}
          size="small"
          fill="clear"
          color="secondary"
        >
          <IonIcon icon={cog}></IonIcon>
        </IonButton>
      </IonButtons>
    ) : null}
  </>);

  const ListBtns = context?.ListBtns || (({ doc }) => {
    if ( isAllowed(entitySlug, ['read', 'instance:read']) && showBtnCrud && showBtnShow && moduleSettings.showBtnShow ) {
      return <IonButton
        size="small"
        fill="clear"
        color="secondary"
        routerLink={attachPrefix(`/a/entity/${entityDoc?.data.nameSlug}/${doc.id}`)}
      >
        Ver
      </IonButton>
    } 
    return null;
  });
  
  if (entitySpecs?.isLoading) {
    return (
      <div className="py-12 flex place-content-center content-center items-center font-brand-main">
        <BadgeLoading className="text-brand-dark" />
      </div>
    );
  }

  if (!forceAllowed && !isAllowed(entitySlug, ['list', 'instance:list'])) {
    return null;
  }

  let dataMode = "all";
  let listStyle = "list";
  if (!fetchItems && entitySpecs?.filterMenuDocList.find(item => item.data.nameSlug === filterMenuSlug)) {
    dataMode = "filter-paginated";
    listStyle = "filter-paginated";
  }

  const navigateToToUse = navigateTo || ((viewType, doc) => (
    viewType === 'form' && attachPrefix(`/a/entity/${entitySpecs?.entitySlug}/${doc ? doc.id : 'new'}/form`)
  ));

  const canShowBtnAdd = props.showBtnAdd // top priority
                        || moduleSettings.showBtnAdd === false // second priority, if true check next priority
                          ? false 
                          : canAddMoreDocs?.canAddMoreDocs; // third priority

  return (
    <SectionCrudModel
      {...moduleSettings}
      {...props}
      model={ExtendedModel}
      entitySlug={entitySlug}
      editStyle="route"
      dataMode={dataMode}
      listStyle={listStyle}
      
      navigateTo={navigateToToUse}
      refresh={refresh}
      onRefresh={() => doRefresh(refresh + 1)}

      // callbacks 
      fetchItems={fetchItems}
      hardFilter={hardFilter}
      onValidation={validateForm}
      handleBeforeSave={handleBeforeSave}
      onDelete={handleDelete}

      // UI
      ListItem={ListDoc}
      ListBtns={ListBtns}
      ExtraActions={ExtraActions}
      EmptyContent={EmptyContent}

      // show
      showBtnAdd={canShowBtnAdd}

      // dataMode 'filter-paginated'
      filterPath={match.url}
      entitySpecs={entitySpecs}
      queryParams={parsedParams}
    />
  );
}

export function EntityList(props) {
  let {
    // only,
    // exclude,
    // instance,
    // entitySlug,
    // parsedParams
    // history,
    // location,
    match
  } = props;
  const { attachPrefix } = usePanel();
  const entitySlug = props.entitySlug || match.params.entitySlug;
  const EntitiesModel = Model.extend('entities');
  const entityDoc = useStateSingleResult({
    Model: EntitiesModel,
    nameSlug: entitySlug
  });

  if (
    !entityDoc?.id
  ) {
    return (
      <div className="py-12 flex place-content-center content-center items-center font-brand-main">
        <BadgeLoading className="text-brand-dark" />
      </div>
    );
  }

  return (
    <EntityCrudList
      {...props}
      {...{
        entityDoc,
        // parsedParams,
        attachPrefix
      }}
      // fetchItems={props.fetchItems}
      navigateTo={props.goToForm}
      filterMenuSlug={props.filterMenuSlug || 'panelSearch'}
    />
  );
}

export function RouteEntityCrud(props) {
  let {
    context,
    withLayout = true,
    match,
    history,
    location,
    showInstanceSelector = true
  } = props;
  const { selectedInstance, attachPrefix } = usePanel();
  const [ selectedInner, setSelectedInner ] = useState(selectedInstance);
  const { action, 0: attrValParams } = props.match.params;
  const entitySlug = context?.entitySlug || match.params.entitySlug;
  const parsedParams = useMemo(() => {
    return {
      ...(props.parsedParams || {}),
      ...parseAttrValParams( attrValParams ),
      ...parseAttrValParams( location.hash.replace('#/', '') )
    };
  }, [location.hash]);
  const ExtendedModel = Model.extend('entities');
  const entityDoc = useStateSingleResult({
    Model: ExtendedModel,
    nameSlug: entitySlug
  });
  const breadcrumbs = context?.breadcrumbs || [
    {
      title: entityDoc?.data?.name
    }
  ];

  useEffect(() => {
    if (selectedInstance?.id && selectedInstance.id !== selectedInner?.id) {
      setSelectedInner(selectedInstance);
    }
  }, [selectedInstance?.id]);

  // for Main instance
    // show instance selector for global entities from main instance
  let TitleToolbarRightToUse = context?.TitleToolbarRight;
  if (selectedInstance?.data.hash === 'main' && showInstanceSelector) {
    TitleToolbarRightToUse = (() => (
      entityDoc?.data && !entityDoc.data.isInstance && (
        <InstanceInlineSelectorForMain selectedInstance={selectedInner} setSelectedInstance={setSelectedInner} />
      )
    ));
  }

  // for others instances
    // dont show instance selector
    // hardFilter lists
    // attach instanceId to saved docs

  if (
    !entityDoc?.id
    || !selectedInner?.id
  ) {
    return (
      <div className="py-12 flex place-content-center content-center items-center font-brand-main">
        <BadgeLoading className="text-brand-dark" />
      </div>
    );
  }
  
  const content = (
    <EntityCrudList
      {...props}
      {...{
        entityDoc,
        parsedParams,
        attachPrefix
      }}
      selectedInstance={selectedInner}
      showEmptyMessage={context?.showEmptyMessage}
      navigateTo={context?.goToForm}
      fetchItems={context?.fetchItems}
      filterMenuSlug={context?.filterMenuSlug || 'panelSearch'}
    />
  );

  if (!withLayout) {
    return content;
  }
  
  return (
    <LayoutAdmin 
      history={props.history} 
      defaultHref={context?.defaultBackRoute}
      breadcrumbs={breadcrumbs}
      TitleToolbarRight={TitleToolbarRightToUse}
    >
      {content}
    </LayoutAdmin>
  );
}
