// import { IonIcon } from "@ionic/react";
// import { 
//   logoGoogle
// } from 'ionicons/icons';
// import { useAuth } from "../../user/AuthContext";
// import { useLoginUtils } from "../../user/Login";
// import BtnLoading from "../../../components/ui/BtnLoading";

import ContactBTN from "../../../components/ContactBTN";
import { AnimationAddClassWhenVisible } from "../../../components/ui/AnimationAddClassWhenVisible";
import BtnSignUpModal from "../../../components/ui/BtnSignUpModal";
import { compileStringTemplate, lineBreaks } from "../../../libs/utils";
import { Background } from "../parts/PartBackground";
import { BtnsList, sectionCardBtnsFields } from "./BlockSectionCardBtns";



export default function () {
  return {
    type: 'cta',
    variant: 'hero',
    template: CtaAHero,
    params: {
      'headerBg': {
        name: 'Fondo',
        type: 'part',
        part: 'background'
      },
    },
    strings: {
      // title, subtitle, content, btnList
      
      'title': {
        type: 'text',
        name: 'Título'
      },
      'subtitle': {
        type: 'text',
        name: 'Subtítulo'
      },
      'content': {
        type: 'text',
        name: 'Contenido'
      },
      'btnList': {
        type: 'collection',
        name: 'Botones',
        fields: sectionCardBtnsFields
      },
    },
  };
};

export function CtaAHero(props, specDesign) {
  let {
    strings, headerBg, history, title, subtitle, content, btnList
  } = props
  // const { user, loginWithGoogleAndVerify } = useAuth();
  // const { redirectOnLogin, handleGoogleSignin } = useLoginUtils(user, loginWithGoogleAndVerify, history, instance);

  return (
    <Background params={headerBg} classes={{ bgContainer: "snap-start" }}>
      <div className="py-32 xs:py-40 lg:py-44 px-4 xs:px-10 md:px-20 bg-gradient-to-b to-brand-primary from-brand-secondary bg-fixed"> 
        <div className="text-center px-4">
          <div className="text-center mx-auto sm:max-w-sm">
            {title ? (
              <AnimationAddClassWhenVisible classToAdd="transition-opacity" classDefault="duration-1000 delay-[100ms]">
                <h3 className="text-4xl md:text-4xl font-bold font-brand-main text-brand-primary-contrast">
                  {lineBreaks(compileStringTemplate(title, specDesign.stringsVars))}
                </h3>
              </AnimationAddClassWhenVisible>
            ) : null}
            {subtitle ? (
              <AnimationAddClassWhenVisible classToAdd="transition-opacity" classDefault="duration-1000 delay-[300ms]">
                <h2 className='text-lg lg:text-xl font-bold font-brand-main text-brand-primary-contrast md:mb-2'>
                  {lineBreaks(compileStringTemplate(subtitle, specDesign.stringsVars))}
                </h2>
              </AnimationAddClassWhenVisible>
            ) : null}
            {content ? (
              <AnimationAddClassWhenVisible classToAdd="transition-opacity" classDefault="duration-1000 delay-[500ms]">
                <p className='mt-4 mx-auto max-w-xs px-4 pb-4 text-lg font-brand-main'>
                  {lineBreaks(compileStringTemplate(content, specDesign.stringsVars))}
                </p>
              </AnimationAddClassWhenVisible>
            ) : null}
          </div>
          {strings?.ctaType === "whatsapp" ? (
            <AnimationAddClassWhenVisible classToAdd="transition-opacity" classDefault="duration-1000 delay-[300ms]">
              <div className="mt-4">
                <ContactBTN className={'mx-auto'} label={lineBreaks(strings.ctaLabel)} design="large" />
              </div>
            </AnimationAddClassWhenVisible>
          ) : null}
          {strings?.ctaType === "signup" ? (
            <AnimationAddClassWhenVisible classToAdd="transition-opacity" classDefault="duration-1000 delay-[300ms]">
              <div className="mt-4">
                <BtnSignUpModal strings={strings} history={history} design="large" classes={{btnButton: 'mx-auto'}} />
              </div>
            </AnimationAddClassWhenVisible>
          ) : null}
          {btnList?.length ? (
            <AnimationAddClassWhenVisible classToAdd="transition-opacity" classDefault="duration-1000 delay-[700ms]">
              <div className="mt-4">
                <BtnsList
                  {...props}
                  items={btnList}
                />
              </div>
            </AnimationAddClassWhenVisible>
          ) : null}
          {/* <BtnLoading
            label="Acceder con Gmail"
            icon={<IonIcon icon={logoGoogle} className="!-mb-1 w-5 h-5 text-red-600" />}
            onClickAsync={handleGoogleSignin}
            className="block mx-auto !items-start !justify-start px-3 py-2 w-[190px] !bg-white !text-gray-900 shadow-md !no-underline hover:underline"
            colorClass="text-gray-400 hover:text-gray-700"
          /> */}
        </div>
      </div>
    </Background>
  );
}