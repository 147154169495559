import _ from "lodash";
import { Fragment, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useAsyncMemo } from 'use-async-memo';
import EntityFilterMenuDisplay from '../../components/EntityFilterMenuDisplay';
import { useEntityFullBySlug } from '../entity/Entity';
import { afterConfirmCart, CurrentCartDetails, CurrentCartDetailsDrawer } from "./CurrentCartDetails";
import { CartProvider, useCart } from "./useCartData";
import { stringifyAttrValParams, trimSlash, withProvider } from "../../libs/utils";
import { useScrollUtils } from "../panel/useScrollUtils";
import { CartItemThumbGridMemo } from "./CartItemThumbGrid";
import BadgeLoading from "../../components/ui/BadgeLoading";
import filterDataToQueryFormatter from "../../components/Filters/filterDataToQueryFormatter";
import getModelQueryCache from "../../libs/ModelQueryCache";
import Model from "../../libs/ModelClass";
import { urlPrefix } from "../instance/utilsInstance";
import { IonInfiniteScroll, IonInfiniteScrollContent } from "@ionic/react";
import { askInmediateDeliveryField, ProductViewRawMemo } from "./BlockProductView";
import { whatsappObjectFields } from "../blockStack/blocks/BlockPageFooterBContactsLinksFixedCta";
import { usePrevious } from "../../libs/usePrevious";
import { useCategoryFields } from "./useCategoryFields";


export const imgProportionField = {
  type: 'select',
  name: 'Proporcion de imagen',
  options: [{
    label: 'Definido en el branding',
    value: 'brand'
  }, {
    label: 'Rectangulo',
    value: 'vertical-square'
  }, {
    label: 'Rectangulo vertical extenso',
    value: 'vertical-wide'
  }],
  defaults: 'vertical-square'
};

export const cartsPathsFields = {
  filterPath: {
    type: 'text',
    defaults: '/m/products/filter'
  },
  viewPath: {
    type: 'text',
    defaults: '/m/products/view'
  },
  cartPath: {
    type: 'text',
    defaults: '/m/carts/view'
  }
};

export default function () {
  return {
    type: 'productGrid',
    variant: 'withFilters',
    template: ProductGridWithFilters,
    params: {
      'filterMenuSlug': {
        type: 'text',
        defaults: 'search'
      },
      ...cartsPathsFields,
      'showFilters': {
        type: 'boolean',
        defaults: true
      },
      'imgProportion': imgProportionField,
      'askInmediateDelivery': askInmediateDeliveryField,
      'sendCartLink': {
        type: 'object',
        name: 'Whatsapp: Enlace del pedido',
        descriptionText: 'Mensaje avisando sobre nuevo pedido',
        fields: whatsappObjectFields
      }
    }
  };
};

export const ProductGridWithFilters = withProvider(ProductGridWithFiltersRaw, CartProvider);

function ProductGridWithFiltersRaw(props) {
  let {
    specDesign,
    instance,
    data,
    history,
    parsedParams,
    isAllowed,
    isMinBreakpointActive,
    match,
    location,
    sendCartLink,
    filterMenuSlug,
    filterPath,
    viewPath,
    cartPath,
    showFilters,
    imgProportion = 'vertical-square'
  } = props;

  const blockId = useMemo(() => (_.uniqueId('item-grid')), []);
  const topElementRef = useRef();
  const { elementToScrollRef } = useScrollUtils();

  const {
    isDetailsOpen, setIsDetailsOpen, openDetailsDrawer,
    entityMap, CartModel, ItemMainModel, ItemVariantModel, CategoryModel, TypeModel, 
    fetchItems, items, itemsTypes, itemsCategories,
    bags, getBagById, getBagTotal, isItemInBag, setItemToBag, getItemsOfBag,
    cartDoc, saveNewCart, saveCurrentCart, closeCart,
    doPopulateBags,
    totalPriceItems
  } = useCart();

  const variantsEntitySlug = entityMap.cartItemVariants.entitySlug;
  const mainsEntitySlug = entityMap.cartItems.entitySlug;
  const entitySpecsVariants = useEntityFullBySlug({ entitySlug: variantsEntitySlug, filterMenuSlug });
  const entitySpecsMains = useEntityFullBySlug({ entitySlug: mainsEntitySlug, filterMenuSlug });
  const { filterMenuTaxonomyTypes } = entitySpecsVariants;

  const fullItemTaxonomyTypes = useMemo(() => {
    if (entitySpecsVariants?.taxonomyTypesDocList && entitySpecsMains?.taxonomyTypesDocList) {
      return [ ...entitySpecsMains.taxonomyTypesDocList, ...entitySpecsVariants.taxonomyTypesDocList ];
    }
    return [];
  }, [entitySpecsMains, entitySpecsVariants]);

  const [ currentPage, setPage ] = useState(1);
  const [ perPage, setPerPage ] = useState(30);
  const [ loading, setLoading ] = useState(true);
  const [ pagesData, setPages ] = useState(null);
  const [ emptyResults, setEmptyResults ] = useState(false);
  const [ currentQueryString, setCurrentQueryString ] = useState(null);
  const [ ItemQueryLibrary, setItemQueryLibrary ] = useState(null);
  const [ totalCount, setTotalCount ] = useState(0);
  const [ isLeaving, setIsLeaving ] = useState(false);
  const { categoryFieldsDocList, categoryFieldsById } = useCategoryFields({ instance });

  const { cartId, mainItemId, variantItemId, variantParams, queryParams } = useMemo(() => {
    let { cartId, main, variant, params, ...queryParams } = parsedParams || {};
    return {
      cartId,
      mainItemId: main,
      variantItemId: variant,
      variantParams: params,
      queryParams
    }
  }, [parsedParams]);

  const prevQueryParams = usePrevious(queryParams);

  const itemCategoriesDocList = useAsyncMemo(async () => {
    const docs = await Model.extend(entityMap.cartItemCategories.entitySlug).filterByAttributes({ deleted: 'false' });
    return docs;
  }, []);

  // si hay seleccionada una categoría, completar con los fields
  const filterTaxonomyTypesByCategory = useMemo(() => {
    if (!itemCategoriesDocList || !categoryFieldsDocList || !filterMenuTaxonomyTypes) { return null; }
    let filters = [ ...filterMenuTaxonomyTypes ];
    if (queryParams.itemCategoryId) {
      let categoryDoc = itemCategoriesDocList.find((categoryDoc) => {
        return categoryDoc.id === queryParams.itemCategoryId;
      });
      let fieldsDoc = categoryFieldsDocList.find((fieldsDoc) => {
        return fieldsDoc.id === categoryDoc.data.fieldsId;
      });
      if (fieldsDoc) {
        fieldsDoc.data.fields.forEach((taxonomyType) => {
          if (taxonomyType.data.isFilter) {
            filters.push({
              id: taxonomyType.id,
              taxonomyType: taxonomyType.data
            });
          }
        });
      }
    }
    return filters;
  }, [itemCategoriesDocList, categoryFieldsDocList, filterMenuTaxonomyTypes, queryParams.itemCategoryId]);

  useEffect(() => {
    if (!ItemQueryLibrary) {
      const ModelExtended = Model.extend(variantsEntitySlug);
      setItemQueryLibrary(getModelQueryCache(ModelExtended, true));
    }
  }, [variantsEntitySlug]);

  const fetchFilteredDocs = async (queryParams, page) => {
    setLoading(true);
    // parse data and remove {main, variant}
    const filterQuery = filterDataToQueryFormatter(queryParams, filterTaxonomyTypesByCategory); 
    // hard filters
    filterQuery.mainAvailable = 'true';
    filterQuery.variantAvailable = 'true';
    filterQuery.deleted = 'false';
    setCurrentQueryString(JSON.stringify(filterQuery));
    let fetchedPages = await ItemQueryLibrary.filterAndGetPages(filterQuery, {
      page: page,
      limit: perPage,
      // orderBy: { field: 'createdAt', direction: 'desc' },
      onFetch(results) {
        // inherit and overwrite data 
        results.forEach((doc) => {
          doc.data.name = doc.data.variantName || doc.data.name;
          doc.data.price = doc.data.variantPrice || doc.data.price;
        });
        return results;
      }
    });
    setEmptyResults(fetchedPages.length === 1 && fetchedPages[0].length === 0);
    setPages(fetchedPages);
    setLoading(false);
  };

  useEffect(() => {
    if (filterTaxonomyTypesByCategory?.length) {
      // move to next page
      if (prevQueryParams === queryParams) {
        fetchFilteredDocs(queryParams, currentPage);
      }
      // reset to page 1
      else {
        fetchFilteredDocs(queryParams, 1);
        topElementRef.current?.scrollIntoView({ behavior: 'instant', block: 'start' });
      }
    }
  }, [currentPage, queryParams, ItemQueryLibrary, filterTaxonomyTypesByCategory]);

  // redirect to cart
  useEffect(() => {
    if (cartId) {
      history.push(urlPrefix(instance, `${trimSlash(cartPath)}/#/cartId/${cartId}`));
    }
  }, [cartId]);

  const totalPages = useAsyncMemo(() => {
    const calcTotalPages = async (currentQueryString) => {
      if (!currentQueryString) { return null; }
      const ModelExtended = Model.extend(variantsEntitySlug);
      let filterQuery = JSON.parse(currentQueryString);
      // hard filters
      filterQuery.mainAvailable = 'true';
      filterQuery.variantAvailable = 'true';
      filterQuery.deleted = 'false';
      let count = await ModelExtended.filterByAttributesCount(filterQuery);
      setTotalCount(count);
      return count / perPage;
    };
    return calcTotalPages(currentQueryString);
  }, [currentQueryString]);

  const nextPage = () => {
    if (currentPage < totalPages) {
      setPage(currentPage + 1);
    }
  }

  const onFormClear = () => {
    history.push(filterPath);
  };

  const onFormChange = (formValues) => {
    if (isLeaving) {
      return null;
    }
    if (formValues) {
      let nextFilters = { ...formValues };
      // si cambio de categoría, eliminar demás filtros
      if (formValues.itemCategoryId !== prevQueryParams?.itemCategoryId) {
        nextFilters = { itemCategoryId: formValues.itemCategoryId };
      }
      const attrValParams = stringifyAttrValParams(nextFilters);
      let url = attrValParams.length ? `${filterPath}/#/${attrValParams}` : filterPath;
      url = urlPrefix(instance, url);
      // omit if is outside, prevent return after close cart
      if (location.pathname.indexOf(filterPath) === -1) {
        return null;
      }
      history.push( url );
    }
  };
  
  const goToItemDetails = useCallback(({ mainId, variantId, params }) => {
    setIsLeaving(true);
    let route = urlPrefix(instance, `${trimSlash(filterPath)}/`);
    route = `${route}/#/${stringifyAttrValParams({
      ...queryParams,
      main: mainId,
      variant: variantId,
      params: _.omit(params, ['variantId'])
    })}`;
    history.push(route);  
  }, [queryParams, parsedParams]);

  const closeItemDetails = () => {
    setIsLeaving(false);
    let route = urlPrefix(instance, `${trimSlash(filterPath)}/`);
    if (_.size(queryParams)) {
      route = `${route}/#/${stringifyAttrValParams({
        ...queryParams
      })}`;
    } else {
      route = `${route}/`;
    }
    history.push(route);
    // small scroll to refresh menu visibility
    if (elementToScrollRef.current?.detil?.currentY) {
      elementToScrollRef.current.scrollToPoint(0, elementToScrollRef.current?.detil?.currentY - 1, 100);
    }
  };

  const onShow = (doc) => {
    goToItemDetails({ mainId: doc.data.mainItemId, variantId: doc.id });
  };

  const confirmHandle = () => {
    setIsLeaving(true);
    let route = urlPrefix(instance, `${trimSlash(filterPath)}/`);
    route = `${route}/#/${stringifyAttrValParams({
      ...queryParams,
      cartId: cartDoc?.id
    })}`;
    history.push(route);
    afterConfirmCart({ instance, cartPath, cartDoc, closeCart, specDesign, sendCartLink });
    setIsDetailsOpen();
  };

  if (entitySpecsVariants.isLoading || entitySpecsMains.isLoading || !itemCategoriesDocList || !categoryFieldsDocList) {
    return (
      <div className="py-12 flex place-content-center content-center items-center font-brand-main">
        <BadgeLoading className="text-brand-primary" />
      </div>
    );
  }

  return (
    <div id={blockId} className={`container-width-wide pb-8 lg:pt-4 lg:px-2 flex flex-col ${showFilters ? 'lg:flex-row' : ''}`}>
      <div ref={topElementRef} className="relative -top-14 lg:-top-16"></div>

      {showFilters ? (
        <div className="lg:basis-4/12 my-4 lg:my-0">
          <CurrentCartDetails
            {...props}
            cartPath={cartPath}
            confirmHandle={confirmHandle}
            classes={{
              totalContainer: "md:mt-2.5 px-2"
            }}
          />
          <EntityFilterMenuDisplay
            {...entitySpecsVariants}
            filterMenuTaxonomyTypes={filterTaxonomyTypesByCategory}
            instance={instance}
            totalCount={totalCount}
            style={'search-collapsible-drawer'}
            filterData={parsedParams}
            defaultPath={filterPath}
            onFormClear={onFormClear}
            onFormChange={onFormChange}
          />
        </div>
      ) : null}

      <div className={`${showFilters ? '' : ''} lg:py-2.5 w-full px-4 lg:px-2`}>
        <div className="grid grid-cols-2 md:grid-cols-3 gap-4 md:gap-5 items-start">
          {pagesData?.map((pageData, index) => (
            <Fragment key={index}>
              <CartItemThumbGridMemo
                items={pageData}
                {...{ onShow, ...props }}
                entitySpecs={{
                  entitySlug: variantsEntitySlug,
                  taxonomyTypesDocList: fullItemTaxonomyTypes
                }}
                classes={{ imgProportion }}
                renderAsGrid={false}
                categoryFieldsDocList={categoryFieldsDocList}
                categoryFieldsById={categoryFieldsById}
              />
            </Fragment>
          ))}
        </div>

        {!loading && emptyResults ? (
          <div className="text-center">
            No hay resultados.
          </div>
        ) : null} 
        
        <IonInfiniteScroll
          onIonInfinite={(ev) => {
            nextPage();
            setTimeout(() => ev.target.complete(), 400);
          }}
        >
          <IonInfiniteScrollContent>
          </IonInfiniteScrollContent>
        </IonInfiniteScroll>
      </div>

      {/* Item details */}
      {fullItemTaxonomyTypes?.length ? (
        <ProductViewRawMemo
          {...props}
          {...{
            mainItemId,
            variantItemId,
            variantParams,
            categoryFieldsById,
          }}
          variantsEntitySpecs={entitySpecsVariants}
          mainsEntitySpecs={entitySpecsMains}
          design="modal"
          goToItemByParams={goToItemDetails}
          dismissModal={closeItemDetails}
          showCurrentCart={false}
        />
      ) : null}

      {/* Drawer */}
      {fullItemTaxonomyTypes?.length ? (
        <CurrentCartDetailsDrawer
          instance={instance}
          history={history}
          goToItemByParams={goToItemDetails}
          categoryFieldsById={categoryFieldsById}
          variantsEntitySpecs={entitySpecsVariants}
          mainsEntitySpecs={entitySpecsMains}
        />
      ) : null}
    </div>
  );
}