import _ from 'lodash';
import { Link } from 'react-router-dom';
import { AnimationAddClassWhenVisible } from '../../../components/ui/AnimationAddClassWhenVisible';
import { PartLogo, usePartLogo } from '../../../modules/blockStack/parts/PartLogo';
import { lineBreaks } from '../../../libs/utils';
import { BtnList } from '../../../modules/blockStack/parts/PartBtnList';
import { Background } from '../../../modules/blockStack/parts/PartBackground';


const videoSrc = "/images/scrolling_hd.mp4";

const SideContent = () => {
  return (
    <div className="relative mx-auto w-[300px] h-[570px] xl:w-[360px] xl:h-[700px] md:scale-[0.9] xl:scale-100 flex items-center content-center drop-shadow-2xl">
      <div className="relative ml-[10px] w-full h-full flex items-center content-center mx-auto">
        <div className="absolute ml-[15px] mt-[5px] w-[255px] h-[560px] xl:ml-[20px] xl:mt-[5px] xl:w-[315px] xl:h-[690px] top-0 left-0 z-10 rounded-[35px] overflow-hidden">
          <video
            src={videoSrc}
            type="video/mp4"
            className="h-full w-full object-scale-down"
            autoPlay={true}
            loop={true}
            muted={true}
            controls={false}
          />
        </div>
        <img src="/images/phone-mockup-white.png" alt="Mockup" className="relative z-20 max-h-full" />
      </div>
    </div>
  );
};

export const CustomHeader =(props) => {
  let params = { 
    // design: 'mini',
    mainBg: { 
      type: 'gradient',
      bgColorFrom: '#211cf4', bgColorTo: '#1ac3ff', bgColorDirection: 'to bottom right'
    }
  };

  return (
    <PageHeaderHeroStatic {...props} {...params} SideContent={SideContent} />
  )
}


function PageHeaderHeroStatic(props) {
  let { strings, history, design, mainBg, SideContent, specDesign } = props;
  let { logoType, logoPath, logoSize } = usePartLogo({
    ...props,
    specPart: specDesign?.parts?.headerLogo
  });
  return ( 
    <Background params={mainBg}>
      {/* intro */}
      <div className="mx-auto pt-6 px-6 pb-28 xs:pt-10 xs:px-10 md:px-20 mb:pb-16 lg:pb-32 xl:pb-44 md:max-w-4xl xl:max-w-6xl">
        <div className="flex flex-col md:flex-row place-content-between">
          <div className="px-8 sm:px-4 md:px-0 md:pr-4">
            {/* logo */}
            <AnimationAddClassWhenVisible classToAdd="transition-opacity" classDefault="duration-1000 delay-[100ms]">
              <div className="mt-24 md:mt-12 lg:mt-16 xl:mt-28">
                <Link to={`/`}>
                  <PartLogo 
                    classes={{ logoContainer: 'md:gap-10' }}
                    {...{ logoType, logoPath, logoSize }}
                  />
                </Link>
              </div>
            </AnimationAddClassWhenVisible>
            {/* content*/}
            {design !== 'mini' ? (
              <div className="mt-12 md:mt-10 xl:mt-20 max-w-sm md:max-w-md lg:max-w-2xl">
                <AnimationAddClassWhenVisible classToAdd="transition-opacity" classDefault="duration-1000 delay-[300ms]">
                  <h1 className='mb-2 text-2xl md:text-3xl xl:text-4xl text-white font-brand-main font-bold'>
                    {lineBreaks(strings?.title)}
                  </h1>
                </AnimationAddClassWhenVisible>
                <AnimationAddClassWhenVisible classToAdd="transition-opacity" classDefault="duration-1000 delay-[500ms]">
                  <h2 className='text-xl md:text-2xl lg:text-3xl text-white/80 font-brand-main font-medium leading-snug'>
                    {lineBreaks(strings?.subtitle)}
                  </h2>
                </AnimationAddClassWhenVisible>
                <AnimationAddClassWhenVisible classToAdd="transition-opacity" classDefault="duration-1000 delay-[700ms]">
                  <div>
                    <BtnList
                      specDesign={specDesign}
                      strings={{ items: strings.btnList }}
                      params={{ type: 'col', gap: '4' }}
                      classes={{ btnListContainer: 'mt-8 w-fit' }}
                      history={history}
                    />
                  </div>
                </AnimationAddClassWhenVisible>
              </div>
            ) : null}
          </div>
          <div className="pt-24 md:pt-0 lg:pt-4 xl:pt-14 xl:pr-20 flex place-content-center">
            <SideContent />
          </div>
        </div>
      </div>
    </Background>
  )
}