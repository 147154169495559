import _ from 'lodash';
import './definitionsBlocks';
import { RouteRender } from './RouteRender';
import { IonButton, IonButtons, IonLabel } from '@ionic/react';
import { usePanel } from '../panel/usePanel';
import dataTypeBlockStack from './dataTypeBlockStack';


const canCreate = (contractModelMetaData) => {
  return _.isUndefined(contractModelMetaData?.serviceLimits?.createPagesBlocks) 
          ? true // true by defaut
          : contractModelMetaData?.serviceLimits?.createPagesBlocks === true;
};

const ListHeaderExtraActionsPages = ({ Model, isAllowed, canAddMoreDocs, entitySlug, entityDoc, location, entitySpecs, moduleSettings }) => {
  const { attachPrefix } = usePanel();
  return (<>
    {!canCreate(canAddMoreDocs.contractModelMetaData) ? (
      <IonButtons slot="start">
        <IonButton
          routerLink={attachPrefix(`/a/instancesBilling/update/#/openSelector/true`)}
          size="small"
          fill="outline"
          color="primary"
        >
          Aumentar Plan
        </IonButton>
        <IonLabel color="primary" className="ml-2 text-xs md:text-sm">
          Para crear páginas personalizadas
        </IonLabel>
      </IonButtons>
    ) : null}
  </>);
};

export default function () {
  return {
    slug: 'blockStack',
    name: 'Páginas públicas',
    scope: 'instance',
    bundles: ['store'],
    entities: [
      'pages'
    ],
    permissions: [
      { slug: 'pages', label: 'Páginas públicas' },
    ],
    routesPublic: {
      'p': {
        Render: RouteRender
      }
    },
    components: {
      'pages': {
        crud: {
          settings: ({ contractModelMetaData }) => {
            return {
              redirectOnSave: false,
              showBtnAdd: canCreate(contractModelMetaData)
            };
          },
          ListHeaderExtraActions: ListHeaderExtraActionsPages
        },
        codedDataTypes: {
          'stack': dataTypeBlockStack
        }
      }
    }
  };
};