import * as contracts from "./contracts";
import * as creditCollections from "./creditCollections";
import dataTypePaymentConfig from "./dataTypePaymentConfig";
import { RoutePaymentsVerify } from "./RoutePaymentsVerify";


export default function () {
  return {
    name: 'Ventas',
    slug: 'sales',
    scope: 'instance',
    bundles: ['starter'],
    entities: [
      'invoices',
      'payments',
      'creditCollections',
      'invoiceBooks',
      'contracts',
      'contractsModels'
    ],
    permissions: [
      { slug: 'invoices', label: 'Facturas', actionsToAdd: ['tab:sales']},
      { slug: 'payments', label: 'Pagos' },
      { slug: 'creditCollections', label: 'Cobros de créditos' },
      { slug: 'invoiceBooks', label: 'Talonarios de facturas' },
      { slug: 'contracts', label: 'Contrataciones' },
      { slug: 'contractsModels', label: 'Modelos de contratos' },
    ],
    routesAdmin: {
      'payments': {
        'verify': {
          permission: { resource: 'instance.payments', action: ['update'] },
          Content: RoutePaymentsVerify
        }
      },
    },
    components: {
      'contracts': {
        crud: {
          settings: () => ({
            columnRightTaxonomyTypesSlugs: null,
          }),
          'FormAppendColumnRight': {
            classes: {
              container: '',
              firstCol: 'md:basis-1/2',
              secondCol: 'md:basis-1/2'
            },
            Render: contracts.FormAppendColumnRight
          },
          'FormAppend': contracts.FormAppend,
          'FormAppend-modelId': contracts.ContractModelForeignForm
        }
      },
      'contractsModels': {
        codedDataTypes: {
          'paymentConfig': dataTypePaymentConfig
        }
      },
      'creditCollections': {
        crud: {
          'FormAppendColumnRight': {
            classes: {
              container: '',
              firstCol: 'md:basis-1/2',
              secondCol: 'md:basis-1/2'
            },
            Render: creditCollections.FormAppendColumnRight
          },
          'FormPrepend-paidDate': creditCollections.FormPrependSelector,
          'FormAppend-paidDate': creditCollections.FormAppendRelatedData
        }
      }
    },
    events: {
      'contracts': {
        afterRead: contracts.afterRead,
        afterSave: contracts.afterSave
      },
      'creditCollections': {
        afterRead: creditCollections.afterRead,
        beforeSave: creditCollections.beforeSave,
        afterSave: creditCollections.afterSave
      }
    }
  }
}