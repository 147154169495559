import { useMemo, useState } from 'react';
import _ from 'lodash';
import { EntityTaxonomyForm, inputClasses } from '../EntityTaxonomyForm';
import { IonButton, IonModal, IonContent, IonHeader, IonToolbar, IonTitle, IonButtons, IonButton as IonButtonIonic } from '@ionic/react';
import ObjectShow from '../../modules/entity/ObjectShow';
import { viewInlineClasses } from '../../modules/entity/docCardsVariants';


const RawInputObjectForm = (props) => {
  let {
    instance,
    entitySlug,
    title,
    name,
    value,
    fields
  } = props;
  const [isOpen, setIsOpen] = useState(false);
  
  const fieldsPrefixed = useMemo(() => {
    return _.map(fields, (field) => ({
      ...field,
      fieldName: `${name}[${field.fieldName}]`
    }));
  }, [fields]);
  
  return (
    <div className="space-y-2">
      <ObjectShow
        instance={instance}
        entitySlug={entitySlug}
        value={value}
        fields={fields}
        classes={viewInlineClasses}
      />
      <IonButton 
        onClick={() => setIsOpen(true)}
        type="button"
      >
        {title}
      </IonButton>
      <IonModal isOpen={isOpen} onDidDismiss={() => setIsOpen(false)}>
        <IonHeader>
          <IonToolbar>
            <IonTitle>{title}</IonTitle>
            <IonButtons slot="end">
              <IonButtonIonic onClick={() => setIsOpen(false)}>Cerrar</IonButtonIonic>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <div className="p-2 mb-64">
            <EntityTaxonomyForm
              taxonomyTypesDataList={fieldsPrefixed}
              classes={inputClasses}
            />
          </div>
        </IonContent>
      </IonModal>
    </div>
  );
};

export default RawInputObjectForm;