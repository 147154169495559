import React, { lazy, Suspense } from 'react';


const Notion = lazy(() => import('./RawInputColor'));

const RawInputColorLazy = ({ render, fallback, ...props }) => {
  return (
    <Suspense fallback={fallback}>
      {render ? render(<Notion {...props} />) : <Notion {...props} />}
    </Suspense>
  );
};

export default RawInputColorLazy;