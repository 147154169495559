import { AnimationAddClassWhenVisible } from "../../../components/ui/AnimationAddClassWhenVisible";
import NotionShowLazy from "../../../components/ui/NotionShowLazy";
import { compileStringTemplate, lineBreaks } from "../../../libs/utils";
import { Background } from "../parts/PartBackground";


export default function () {
  return {
    type: 'textGrid',
    variant: 'extended',
    title: 'Extendido',
    template: TextGridAExtended,
    params: {
      'gridBg': {
        name: 'Fondo',
        type: 'part',
        part: 'background'
      },
    },
    // fields of text tab
    strings: {
      'items': {
        type: 'collection',
        name: 'items',
        fields: {
          'title': {
            type: 'textArea',
            name: 'Título'
          },
          'subtitle': {
            type: 'textArea',
            name: 'Subtítulo'
          },
          'content': {
            type: 'textArea',
            design: 'notion',
            name: 'Contenido'
          },
        }
      },
    }
  };
};

export function TextGridAExtended({ items, gridBg, specDesign }) {
  return (
    <Background params={gridBg} classes={{ bgContainer: "snap-start" }}>
      <div className="container-width py-40 text-left">
        <div className="flex flex-wrap gap-24 lg:gap-44">
          {items?.map(({title, subtitle, content}, index) => (
            <div key={index}> 
              {title ? (
                <AnimationAddClassWhenVisible classToAdd="transition-opacity" classDefault="duration-1000 delay-[100ms]">
                  <h1 className='text-black font-brand-main font-semibold text-4xl lg:text-5xl mb-2'>
                    {lineBreaks(compileStringTemplate(title, specDesign.stringsVars))}
                  </h1>
                </AnimationAddClassWhenVisible>
              ) : null}
              {subtitle ? (
                <AnimationAddClassWhenVisible classToAdd="transition-opacity" classDefault="duration-1000 delay-[300ms]">
                  <h2 className='text-black font-brand-main font-semibold text-xl lg:text-2xl mb-2'>
                    {lineBreaks(compileStringTemplate(subtitle, specDesign.stringsVars))}
                  </h2>
                </AnimationAddClassWhenVisible>
              ) : null}
              {content ? (
                <NotionShowLazy value={content} render={(children) => (
                  <AnimationAddClassWhenVisible classToAdd="transition-opacity" classDefault="duration-1000 delay-[500ms]">
                    <div>
                      {children}
                    </div>
                  </AnimationAddClassWhenVisible>
                )} />
              ) : null}
            </div>
          ))}
        </div>
      </div>
    </Background>
  );
}
